import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import * as JsSIP from 'jssip';
import * as constants from '../constants';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class JssipService {
  ip;
  session;
  rtcSession;
  options;
  ua;
  callState = constants.STATE_HUNG;
  audio;
  ringInterval;
  tones = 0;
  maxTones = 6;
  hangupCause;
  alert;
  toast;
  status;
  interval;

  constructor(public alertCtrl: AlertController, private toastController: ToastController, public utils: UtilsService) {

  }

  setup(extension_id, extension_pass) {
    var socket = new JsSIP.WebSocketInterface('ws://' + this.ip + '/ws');

    var remoteAudio = window.document.createElement(
      'audio'
    ) as HTMLAudioElement;
    remoteAudio.autoplay = true;
    window.document.body.appendChild(remoteAudio);

    var configuration = {
      sockets: [socket],
      uri: 'sip:' + extension_id + '@' + this.ip,
      password: extension_pass,
      register: true
    };


      this.ua = new JsSIP.UA(configuration);
    

    

    // alert('on setup jssip ' + extension_id)
    

    this.ua.on('registered', (e) => {
      console.error('registered')
      this.status = 'Registered';
      this.presentToastType('success', 'success', 2000);
      // Your code here
    });
    this.ua.on('unregistered', (e) => {
      console.error('registered')
      this.status = 'Unregistered';
      // Your code here
    });
    this.ua.on('registrationFailed', (e) => {
      console.error('unregistered')
      this.status = 'Unregistered';
      this.presentToastType('error', 'danger', 2000);
      // Your code here
    });

    this.ua.start();

    // Register callbacks to desired call events
    var eventHandlers = {
      progress: (e) => {
        this.callState = constants.STATE_CALLING;
        console.log('call is in progress', this.ua);
      },
      failed: (e) => {
        this.callState = constants.STATE_HUNG;
        console.log('call failed with cause: ' + JSON.stringify(e));
        // this.stopRing();
        this.hangup(false);
      },
      ended: (e) => {
        this.callState = constants.STATE_HUNG;
        console.log('aqui');
        //this.stopRing();
        this.hangup(false);
      },
      confirmed: (e) => {
        this.callState = constants.STATE_ONLINE;
        console.log('call confirmed');
        this.stopRing();
        if (this.toast != undefined)
          this.toast.dismiss();

        /* setTimeout(() => {
           this.sendTone(1);
         }, 4000);*/
      },
    };

    this.options = {
      eventHandlers: eventHandlers,
      mediaConstraints: { audio: true, video: false },
    };

    this.ua.on('newRTCSession', (data) => {
      this.session = data.session
      if (this.session.direction === 'incoming') {
        this.onCall(this.session.remote_identity.uri.user);
        console.log(this.session)
        this.session.on('accepted', () => {
          console.log('accepted');

          // the call has answered
        });
        this.session.on("confirmed", () => {
          console.log('confirmed');
          // this handler will be called for incoming calls too
        });
        this.session.on("ended", () => {
          console.log('ended');
          // the call has ended
          this.hangup(false);
        });
        this.session.on("failed", () => {
          console.log('failed');
          // unable to establish the call
          this.hangup(false);
        });
        this.session.on('peerconnection', (data) => {
          data.peerconnection.addEventListener('addstream', (e) => {
            // set remote audio stream
            console.log(e.stream);
            remoteAudio.srcObject = e.stream;
          });
        });
      }

      if (this.session.connection)
        this.session.connection.addEventListener('addstream', (e) => {
          // set remote audio stream
          console.log(e.stream);
          remoteAudio.srcObject = e.stream;
          // remoteAudio.onloadedmetadata = function(e){remoteAudio.play()}
        });
    });

  }

  sendTone(tone) {
    console.log('send dtmf tone.... ' + tone);

    let src = '';
    if (tone == '#')
      src = '../../assets/sounds/dtmf/dtmf-#.mp3';
    else
      src = tone == '*' ? '../../assets/sounds/dtmf/dtmf-star.mp3' : '../../assets/sounds/dtmf/dtmf-' + tone + '.mp3';

    this.audio = new Audio(src);
    this.audio.play();

    if (this.callState == constants.STATE_ONLINE)
      this.session.sendDTMF(tone);
  }

  register() {
    console.log('on registering...')
    this.ua.start();
  }

  onCall(user) {
    this.playIncomingRing();
    //this.callPeer = call;
    this.presentAlertIncoming(user);
    //this.setConnectionEvents(this.conn);
  }

  call(destiny) {

    if (this.callState != constants.STATE_CALLING) {
      this.callState = constants.STATE_CALLING;
      // this.callbtn.className = 'btn-call-calling';

     // alert('connecting to ' + destiny + ' ip ' + this.ip);

      console.log('connecting to ' + destiny + ' ip ' + this.ip);

      this.presentToastType('Llamando...', 'success', 20000);

      this.session = this.ua.call('sip:' + destiny + '@' + this.ip, this.options);

      setTimeout(() => {
        this.playOutboundRing();
      }, 10);

    }



  }

  hangup(terminate_session: boolean) {
    console.log(this.session);

    if (terminate_session) {
      try {
        this.session.terminate();
      } catch (error) {
        console.error('error... ' + error)
      }
    }

    this.tones = 0;

    this.callState = constants.STATE_HUNG;

    this.stopRing();

    this.presentToastType('Llamada colgada', 'danger', 2000);

    if (this.toast != undefined)
      this.toast.dismiss();

    if (this.alert != undefined)
      this.alert.dismiss();

    
  }


  async presentAlertIncoming(peerdisplayname) {
    console.log('targetUser ' + peerdisplayname);
    this.alert = await this.alertCtrl.create({
      cssClass: 'alertCustomCss',
      message: 'Llamada entrante de ' + peerdisplayname,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Rechazar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.hangup(true);
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            console.log('Confirm Okay');
            this.answer();
          }
        }
      ]
    });
    await this.alert.present();
  }

  answer() {
    // this.answered = true;
    //this.yourConn.setRemoteDescription(new RTCSessionDescription(this.offer));

    this.stopRing();
    this.callState = constants.STATE_ONLINE;
    //this.callbtn.className = 'btn-call-calling';


    var callOptions = {
      mediaConstraints: {
        audio: true, // only audio calls
        video: false,
      },
    };
    console.log(this.session);
    this.session.answer(callOptions);


    //create an answer to an offer 
    /* this.yourConn.createAnswer((answer) => {
 
 
       this.yourConn.setLocalDescription(answer);
 
       this.send({
         type: "answer",
         answer: answer
       });
 
     }, (error) => {
       alert("Error when creating an answer");
     });*/
  }



  playIncomingRing() {
    const src = '../../assets/sounds/Ring1.mp3';
    this.audio = new Audio(src);
    this.audio.play();
    this.ringInterval = setInterval(() => {
      this.audio.play();
    }, 2000);
  }

  playOutboundRing() {
    console.log('playing interval');
    const src = '../../assets/sounds/RingOutbound.mp3';
    this.audio = new Audio(src);
    this.audio.play();

    if (this.ringInterval) {
      clearInterval(this.ringInterval);
    }

    this.ringInterval = setInterval(() => {
      console.log('ringing interval');

      this.tones++;

      if (this.tones >= this.maxTones) {
        this.hangupCause = 'busy';
        this.hangup(true);
      } else {
        this.audio.play();
      }
    }, 4000);
  }

  stopRing() {
    try {
      clearInterval(this.ringInterval);
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    } catch (error) {

    }
  }

  async presentToastType(message, color, time) {
    try {
      this.toast.dismiss();
    } catch (e) { }

    this.toast = await this.toastController.create({
      message: message,
      color: color,
      duration: time,
      position: ('bottom')
    });
    this.toast.present();
  }


}




